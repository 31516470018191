
import { useQuery } from '@tanstack/react-query';
import api from 'services/api';

import useToken from './useToken';

const useScrapedData = (configurationToken) => {
    const { data: token } = useToken();

    const scrapedData = useQuery({
        queryKey: ['scrapedData', configurationToken],
        queryFn: api.getScrapedData(token, configurationToken),
        enabled: !!configurationToken
    });

    return scrapedData;
};

export default useScrapedData;
