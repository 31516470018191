
import { useQuery } from '@tanstack/react-query';
import api from 'services/api';
import { validate } from 'uuid';

import useToken from './useToken';

const useConfiguration = (configurationToken) => {
    const { data: token } = useToken();

    const scrapedData = useQuery({
        queryKey: ['configuration', configurationToken],
        queryFn: api.getConfiguration(token, configurationToken),
        enabled: !!configurationToken && validate(configurationToken),
        retry: 2,
    });

    return scrapedData;
};

export default useConfiguration;
