import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Col, Row, Space, Spin, Typography } from 'antd';

import usePostConfiguration from 'hooks/usePostConfiguration';
import { replaceTags } from 'utils/replaceTags';

import StepsContext from '../../context';
import Overlay from '../Overlay';
import './styles.scss';

const ConfigurationInfo = ({ title, data, emptyMessage }) => {
    return (
        <div className='configuration-information'>
            <Typography.Title level={4}>
                {title}
            </Typography.Title>
            {0 < data?.length
                ? (
                    <div className='information'>
                        {data?.map(({ title, value }, index) => (
                            <Fragment key={index}>
                                <div className='title'>
                                    {title}:
                                </div>
                                <div className='value'>
                                    {value}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                )
                : emptyMessage || 'No data'
            }
        </div>
    );
};

const InformationSection = () => {
    const {
        configuration,
        foundTags,
        tags,
    } = useContext(StepsContext);

    const parsedConfiguration = useMemo(() => JSON.parse(configuration), [configuration]);

    const configurationValues = useMemo(() => [
        {
            title: 'Extranet URL',
            value: parsedConfiguration.extranetUrl
        }
    ], [parsedConfiguration]);

    const tagValues = useMemo(() => foundTags.map((tag) => ({
        title: tag,
        value: tags[tag] || 'Not set'
    })), [tags, foundTags]);

    return (
        <Row align={'center'}>
            <Col md={8}>
                <ConfigurationInfo
                    title={'Configuration'}
                    data={configurationValues}
                />
            </Col>
            <Col md={8}>
                <ConfigurationInfo
                    title={'Values'}
                    data={tagValues}
                    emptyMessage={'No tag has been set'}
                />
            </Col>
        </Row>
    );
};

const Confirmation = () => {
    const {
        configuration,
        tags,
        setNextButtonAction,
        setNextButtonLabel,
        setIsNextDisabled
    } = useContext(StepsContext);

    const {
        data: configurationData,
        postConfiguration,
        isAwaitingMutation
    } = usePostConfiguration();

    const [error, setError] = useState(null);
    const [isScraping, setIsScraping] = useState(false);

    const launch = useCallback(async () => {
        postConfiguration({
            configuration: replaceTags(configuration, tags)
        });
    }, [postConfiguration, configuration, tags]);

    useEffect(function setStepButtonsBehavior() {
        setNextButtonAction(() => launch);
        setNextButtonLabel('Launch');

        return () => {
            setNextButtonAction(null);
            setNextButtonLabel(null);
        };
    }, [launch, setNextButtonAction, setNextButtonLabel]);

    useEffect(function validateConfiguration() {
        const parsedConfiguration = JSON.parse(configuration);

        switch (true) {
            case !!parsedConfiguration.extranetUrl:
                setIsNextDisabled(false);
                setError(null);
                break;

            default:
                setIsNextDisabled(true);
                setError('It seems that you have not set the Extranet URL');
                break;
        }

        return () => {
            setIsNextDisabled(false);
        };
    }, [configuration, setIsNextDisabled]);

    useEffect(function openNewTabAfterMutation() {
        if (!isAwaitingMutation && configurationData?.token) {
            const newWindow = window
                .open(`https://gateway.hubyone.com/redirect/?token=${configurationData?.token}`, '_blank');

            newWindow.addEventListener('extensionJWT', () => {
                newWindow.close();
            });
            setIsScraping(true);
        }
    }, [configurationData, isAwaitingMutation]);

    return (
        <>
            <Spin spinning={isAwaitingMutation}>
                <Space direction='vertical' size={'large'} style={{ width: '100%' }}>
                    <Row gutter={16} align={'center'}>
                        <Col>
                            <Typography.Title level={3}>
                                {'Is this alright?'}
                            </Typography.Title>
                        </Col>
                    </Row>
                    {error && (
                        <Row gutter={16} align={'center'}>
                            <Col>
                                <Typography.Title level={4} type={'danger'}>
                                    {error}
                                </Typography.Title>
                            </Col>
                        </Row>
                    )}
                    <InformationSection />
                    <Row align='center'>
                        <Col md={8}>
                            <Typography.Text>
                                {'A new tab will open and the extension will start scraping the website using the provided values.'}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Space>
            </Spin>
            <Overlay
                isScraping={isScraping}
                setIsScraping={setIsScraping}
                launch={launch}
            />
        </>
    );
};

export default Confirmation;
