import axiosInstance from './instance';

const api = {
    endpoints: null,

    getEndpoints: async () => {
        const response = await axiosInstance.get('/endpoints.json');

        api.endpoints = response.data;

        return response.data;
    },

    getToken: async () => {
        const response = await axiosInstance.get(api.endpoints.TOKEN_URL);

        return response.data;
    },

    getConfiguration: (token, configurationToken) => async () => {
        const response = await axiosInstance.get(`${api.endpoints.API_URL}/configurations/${configurationToken}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    },

    postConfiguration: (token) => async (configuration) => {
        const response = await axiosInstance.post(`${api.endpoints.API_URL}/configurations/extension`, configuration, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    },

    getScrapedData: (token, configurationToken) => async () => {
        const response = await axiosInstance.get(`${api.endpoints.API_URL}/configurations/${configurationToken}/scraped-data`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    },
};

export default api;
