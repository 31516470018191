import { QueryClientProvider } from '@tanstack/react-query';
import api from 'services/api';
import { queryClient } from 'services/queryClient';

import AppRoutes from './routes';

import 'antd/dist/antd.min.css';
import './styles/styles.scss';

const App = () => {
    queryClient.prefetchQuery({
        queryKey: ['endpoints'],
        queryFn: api.getEndpoints
    });

    return (
        <QueryClientProvider client={queryClient}>
            <AppRoutes />
        </QueryClientProvider>
    );
};

export default App;
