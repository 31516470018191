import {
    BrowserRouter,
    createRoutesFromChildren,
    Route,
    Routes
} from 'react-router-dom';

import Layout from '../components/Layout';
import Configuration from '../pages/Configuration';

const routes = (
    <>
        <Route
            path='/'
            element={<Layout />}
        >
            <Route
                index
                element={<Configuration />}
            />
        </Route>
    </>
);

export const routesDefinitions = createRoutesFromChildren(routes);

const AppRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>
                {routes}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
