import React from 'react';
import { Outlet } from 'react-router';

import './styles.scss';

const Layout = () => {
    return (
        <div>
            <header className="header">
                <div className="group-logo">
                    <img src="/img/b2l-logo.svg" alt="Bee2Link Group" />
                </div>
                <div className="huby-logo">
                    <img src="/img/huby-logo-complete.png" alt="HubyOne Logo" />
                    <span className="text">HubyOne Tester</span>
                </div>
            </header>
            <div className='content'>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
