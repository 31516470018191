
import { useQuery } from '@tanstack/react-query';
import api from 'services/api';

const useToken = () => {
    const token = useQuery({
        queryKey: ['token'],
        queryFn: api.getToken,
        staleTime: 1000 * 60
    });

    return token;
};

export default useToken;
