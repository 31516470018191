import { useCallback, useEffect, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'services/api';

import useToken from './useToken';

const usePostConfiguration = () => {
    const { data: token, isLoading: isTokenLoading } = useToken();
    const [shouldMutate, setShouldMutate] = useState(false);
    const [configuration, setConfiguration] = useState(null);

    const queryClient = useQueryClient();

    const mutation = useCallback((configuration) => {
        setShouldMutate(true);
        setConfiguration(configuration);
    }, []);

    const { mutate, ...functions } = useMutation({
        mutationFn: api.postConfiguration(token),
        onSettled: () => {
            setShouldMutate(false);
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['configurationToken'], data);
        }
    });

    useEffect(() => {
        if (token && !isTokenLoading && shouldMutate) {
            mutate(configuration);
        }
    }, [token, configuration, isTokenLoading, shouldMutate, mutate]);

    return {
        postConfiguration: mutation,
        isAwaitingMutation: shouldMutate,
        ...functions
    };
};

export default usePostConfiguration;
