import React, { useEffect, useMemo, useState } from 'react';

import { Button, Space, Steps } from 'antd';

import StepsContext from './context';
import Confirmation from './partials/Confirmation';
import File from './partials/File';
import Tags from './partials/Tags';
import './styles.scss';

const { Step } = Steps;

const steps = [
    {
        title: 'Configuration',
        element: File
    },
    {
        title: 'Tags',
        element: Tags
    },
    {
        title: 'Confirmation',
        element: Confirmation
    }
];

const tagRegex = /<@[A-Z_]+?>/g;

const TAGS_LOCAL_STORAGE_KEY = 'huby-tester-tags';

const Configuration = () => {
    const [current, setCurrent] = useState(0);
    const [configuration, setConfiguration] = useState();
    const [tags, setTags] = useState(JSON.parse(localStorage.getItem(TAGS_LOCAL_STORAGE_KEY)) || {});
    const [isPreviousDisabled, setIsPreviousDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isPreviousHidden, setIsPreviousHidden] = useState(true);
    const [nextButtonAction, setNextButtonAction] = useState(null);
    const [nextButtonLabel, setNextButtonLabel] = useState(null);

    const CurrentStep = useMemo(() => steps[current].element, [current]);

    const foundTags = useMemo(() => [
        ...new Set(
            configuration && [...configuration.matchAll(tagRegex)].map((tag) => tag[0])
        )
    ], [configuration]);

    useEffect(() => {
        localStorage.setItem(TAGS_LOCAL_STORAGE_KEY, JSON.stringify(tags));
    }, [tags]);

    return (
        <StepsContext.Provider
            value={{
                setCurrent,
                configuration,
                setConfiguration,
                tags,
                setTags,
                setIsPreviousDisabled,
                isNextDisabled,
                setIsNextDisabled,
                setIsPreviousHidden,
                setNextButtonAction,
                setNextButtonLabel,
                foundTags
            }}
        >
            <div className='configuration-steps-page'>
                <div className='steps-wrapper'>
                    <Steps current={current} onChange={setCurrent}>
                        {steps.map((step, index) => (
                            <Step
                                key={step.title}
                                title={step.title}
                                description={step.description}
                                disabled={index > current}
                            />
                        ))}
                    </Steps>
                </div>
                <div className='steps-content'>
                    <CurrentStep />
                </div>
                <div className='steps-buttons'>
                    <Space>
                        {!isPreviousHidden && (
                            <Button
                                type='primary'
                                ghost
                                onClick={() => setCurrent(current - 1)}
                                disabled={isPreviousDisabled}
                            >
                                {'Back'}
                            </Button>
                        )}
                        <Button
                            type='primary'
                            onClick={nextButtonAction || (() => setCurrent(current + 1))}
                            disabled={isNextDisabled}
                        >
                            {nextButtonLabel || 'Next'}
                        </Button>
                    </Space>
                </div>
            </div>
        </StepsContext.Provider>
    );
};

export default Configuration;
