import React, { Fragment, useContext, useEffect } from 'react';

import { Col, Input, Row, Typography } from 'antd';

import StepsContext from '../../context';
import './styles.scss';

const Tags = () => {
    const {
        foundTags,
        tags,
        setTags,
        setIsPreviousDisabled,
        setIsPreviousHidden
    } = useContext(StepsContext);

    const handleChange = (id) => (event) => {
        const { value } = event.target;

        setTags((prevTags) => ({
            ...prevTags,
            [id]: value,
        }));
    };

    useEffect(function fillNewTagVales() {
        if (0 < foundTags.length) {
            setTags((prevTags) => ({
                ...foundTags.reduce((acc, tag) => ({
                    ...acc,
                    [tag]: '',
                }), {}),
                ...prevTags,
            }));
        }
    }, [foundTags, setTags]);

    useEffect(() => {
        setIsPreviousDisabled(false);
        setIsPreviousHidden(false);
    }, [setIsPreviousDisabled, setIsPreviousHidden]);

    return (
        <>
            <Row align={'center'}>
                <Col>
                    <Typography.Title level={4}>
                        {0 < foundTags.length
                            ? 'We have found these tags on your configuration file:'
                            : 'We haven\'t found any tags on your configuration file.'
                        }
                    </Typography.Title>
                </Col>
            </Row>
            <Row gutter={16} align={'center'}>
                <Col span={12}>
                    <div className='tags-wrapper'>
                        {foundTags.map((tag) => (
                            <Fragment key={tag}>
                                <label className='tag-label' htmlFor={tag}>
                                    {tag}
                                </label>
                                <Input
                                    id={tag}
                                    className={'tag-input'}
                                    value={tags[tag]}
                                    onChange={handleChange(tag)}
                                />
                            </Fragment>
                        ))}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Tags;
