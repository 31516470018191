import React, { useContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Input, Spin } from 'antd';

import classNames from 'classnames';
import useConfiguration from 'hooks/useConfiguration';
import { validate } from 'uuid';

import StepsContext from '../../context';

import './styles.scss';

const File = () => {
    const {
        configuration,
        setConfiguration,
        isNextDisabled,
        setIsNextDisabled,
        setIsPreviousHidden
    } = useContext(StepsContext);
    const [error, setError] = useState(null);
    const [token, setToken] = useState('');

    const {
        data: {
            configuration: tokenConfiguration
        } = {},
        isFetching,
        error: configurationError
    } = useConfiguration(token);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                setConfiguration(reader.result);
            };
            reader.readAsText(file);
        });
    }, [setConfiguration]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'application/json': ['.json'],
        },
        useFsAccessApi: false,
        noClick: true,
        onDrop
    });

    useEffect(() => {
        setError(null);
        if (configuration) {
            try {
                JSON.parse(configuration);
                setIsNextDisabled(false);
            } catch (error) {
                setError('Please input a valid JSON file.');
                setIsNextDisabled(true);
            }
            return;
        }

        setIsNextDisabled(true);
    }, [configuration, setIsNextDisabled]);

    useEffect(() => {
        if (tokenConfiguration) {
            setConfiguration(JSON.stringify(tokenConfiguration, null, 4));
        }
    }, [tokenConfiguration, setConfiguration]);

    useEffect(() => {
        setIsPreviousHidden(true);
    }, [setIsPreviousHidden]);

    const styles = classNames(
        'dropzone-wrapper', {
            '-focus': isFocused,
            '-accept': isDragAccept || !isNextDisabled,
            '-reject': isDragReject || error,
        }
    );

    return (
        <Spin spinning={isFetching}>
            <div className={'token-configuration'}>
                <div>
                    <label
                        htmlFor='token'
                        className='token-label'
                    >
                    Token
                    </label>
                    <Input
                        id='token'
                        className='token-input'
                        value={token}
                        onChange={(event) => setToken(event.target.value)}
                        placeholder='Paste the configuration token here.'
                        status={(configurationError || (token && !validate(token))) && 'error'}
                    />
                </div>
            </div>
            <div className='divider'>
                <span className='divider-text'>or</span>
            </div>
            <div className={styles}>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <textarea
                        className='configuration-textarea'
                        value={configuration}
                        onChange={(event) => setConfiguration(event.target.value)}
                        placeholder='Paste or drop a configuration file here.'
                    >
                        {configuration}
                    </textarea>
                </div>
            </div>
        </Spin>
    );
};

export default File;
